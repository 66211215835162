import React from "react";
import useLinks from "@hooks/useLinks";
import "./LinksContainer.scss";
import { LINKS } from "@constants";

const LinksContainer = () => {
    const generateLinks = useLinks();

    return (
        <div className="footer-links">
            <div className="footer-links__section footer-links__section-first">
                <div className="footer-links__section-container footer-links__section-container__flex">
                    <div className="footer-links__section-wrapper">
                        <p className="footer-links__section-container-title">
                            Services
                        </p>
                        {generateLinks(LINKS.services)}
                    </div>
                    <div className="footer-links__section-wrapper">
                        <p className="footer-links__section-container-title">
                            Technologies
                        </p>
                        {generateLinks(LINKS.subServices)}
                    </div>
                </div>
                <div className="footer-links__section-container">
                    <div className="footer-links__section-wrapper">
                        <p className="footer-links__section-container-title">
                            Industry
                        </p>
                        {generateLinks(LINKS.industry)}
                    </div>
                </div>
            </div>
            <div className="footer-links__section footer-links__section-second">
                <div className="footer-links__section-container">
                    <div className="footer-links__section-wrapper">
                        <p className="footer-links__section-container-title">
                            Expertise
                        </p>
                        {generateLinks(LINKS.expertise)}
                    </div>
                </div>
                <div className="footer-links__section-container">
                    <div className="footer-links__section-wrapper">
                        <p className="footer-links__section-container-title">
                            Company
                        </p>
                        {generateLinks(LINKS.company)}
                        {generateLinks(LINKS.rest)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinksContainer;
